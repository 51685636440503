<!--登录页面-->
<template>
  <div class="content">
    <div class="welcome">
      <div>欢迎来到助手AI</div>
      <div>AI世界即将为您开启</div>
    </div>
    <div class="main">
      <div class="mainContainer">
       <div class="loginName">账号登录</div>
       <div class="main_form">
         <van-form @submit="onSubmit">
           <van-cell-group inset>
             <van-field
                 v-model="form.username"
                 name="请输入手机号"
                 placeholder="请输入手机号"
                 :rules="[{ required: true, message: '请输入手机号' },
                { validator: validatorTel, message: '手机号输入不合法' }]"
             />
             <van-field
                 v-model="form.password"
                 :type="isShow ? 'text' : 'password' "
                 name="请输入密码"
                 placeholder="请输入密码"
                 :right-icon="isShow ? 'eye-o' : 'closed-eye'"
                 @click-right-icon="changePasswordIcon"
                 :rules="[{ required: true, message: '请输入密码' }]"
             />
           </van-cell-group>
           <div>
             <van-button block type="primary" native-type="submit" class="formButton" color="#16a37f">
               登录
             </van-button>
           </div>
         </van-form>
         <div class="row-between toPasswordRegister">
           <div class="pointerCursor" @click="$router.push('/forgetPassword')">
             忘记密码？
           </div>
           <div class="pointerCursor" @click="$router.push('/register')">
             新用户账号注册
           </div>
         </div>
       </div>
      </div>
      <explanatory></explanatory>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import explanatory from '@/components/explanatory'
export default {
  setup () {
    const isShow = ref(false)
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      form: {
        username: '',
        password: ''
      },
      validatorTel: false,
      phoneGui: /^[0-9]{11}$/
    })
    state.validatorTel = (val) => state.phoneGui.test(val)
    const onSubmit = (values) => {
      store.dispatch('Login', state.form).then(() => {
        store.dispatch('GetInfo').then(() => {
          // 判断会员等级
          if (store.getters?.level == 0) {
            router.push('/')
          } else {
            router.push('/chart')
          }
        })
      }).catch(() => {
        // loading.value = false;
        // // 重新获取验证码
        // if (captchaOnOff.value) {
        //   getCode();
        // }
      })
    }
    const methods = reactive({
      changePasswordIcon () {
        isShow.value = !isShow.value
      }
    })
    return {
      isShow,
      onSubmit,
      ...toRefs(methods),
      ...toRefs(state)
    }
  },
  components: {
    explanatory
  }
}

</script>

<style scoped lang="less">
.content{
  padding: 20vh 0 10vh;
  box-sizing: border-box;
  .welcome{
    padding-left: 6.6vh;
    width: 100%;
    box-sizing: border-box;
  }
  .main{
    height: calc(100% - 6vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .mainContainer{
      padding-left: 6.6vh;
      padding-top: 6.6vh;
      .loginName{
        width: 100px;
        position: relative;
        &:after{
          width: 40px;
          height: 4px;
          background: black;
          content: '';
          position: absolute;
          bottom: -4px;
          left: 1px;
        }
      }
      .main_form{
        width: 100%;
        padding-top: 6vh;
        padding-right: 6vh;
        box-sizing: border-box;
        .van-cell-group--inset {
          margin: 0px;
          border-radius: 0;
          .van-cell{
            border: 1px solid #D8D8D8;
            margin-top: 1vh;
          }
        }
        .formButton{
          margin-top: 2vh;
          box-sizing: border-box;
        }
        .toPasswordRegister{
          padding: 0.1rem 0;
        }
      }
    }
  }
}
</style>
